
import { Component, Vue } from 'vue-property-decorator';
import { URI_ARTICLE_PREVIEW } from '@/api/endpoints';
import { ArticlePreviewData } from './models';
import { ApiWidget } from '@/models/widgets';
import {
  DynamicWidget
} from '@/shared/components';
import { USER_NOTIFY } from '@/store/list';
import { namespace } from 'vuex-class';

const AppStore = namespace('AppStore');

@Component({
  components: {
    DynamicWidget
  }
})
export default class ArticlePreview extends Vue {
  articleData: ArticlePreviewData | null = null;
  widgets = [] as ApiWidget[];

  async mounted (): Promise<void> {
    await this.fetchArticleData();
  }

  @AppStore.Action(USER_NOTIFY) notify: any;

  async fetchArticleData (): Promise<void> {
    try {
      this.articleData = (await this.$api.get<ArticlePreviewData>(URI_ARTICLE_PREVIEW(this.$route.params.hash))).data;
      this.widgets = this.articleData.components;
    } catch (e) {
      this.notify({ msg: 'Nie udało się pobrać artykułu', type: 'error', permanent: true });
    }
  }
}
